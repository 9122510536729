<template>
  <div class="pb-20">
    <div class="mb-10">
      <h2 class="text-2xl font-bold">Loans</h2>
    </div>

    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :url="`${$baseurl}/admin/personal/loans`"
        :columns="loans.columns"
        :query="loans.query"
        :on-click="click"
        sort="loans.created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="loans.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
          <div class="flex items-start flex-wrap xl:flex-no-wrap">
            <div class="flex flex-wrap items-center w-full">
              <span class="processor-filter-title">Filters:</span>
              <template v-for="(filter, index) in loans.filters">
                <span
                  v-html="filter.title"
                  :key="index"
                  class="processor-filter-item"
                  :class="{ active: currentFilter === filter }"
                  @click.prevent="toggleFilter(filter)"
                />
              </template>
            </div>
          </div>
        </template>
        <template v-slot:td-5="{ item: { row } }">
          <badge-loan-status :loan="row" />
        </template>
      </datatable>
    </div>

    <modals-loan
      ref="loanModal"
      :loan="loans.selected"
      @close="loanModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      loans: this.$options.resource([], {
        selected: null,
        query: '',
        filters: [
          {
            name: 'pending',
            title: 'Pending',
          },
          {
            name: 'approved',
            title: 'Approved',
          },
          {
            name: 'declined',
            title: 'Declined',
          },
          {
            name: 'accepted',
            title: 'Accepted',
          },
          {
            name: 'rejected',
            title: 'Rejected',
          },
          {
            name: 'disbursed',
            title: 'Disbursed',
          },
          {
            name: 'paid',
            title: 'Paid Off',
          },
        ],
        columns: [
          {
            name: 'users.name',
            th: 'Customer Name',
            render: ({ user }) => `${user?.name} ${user?.last_name}`,
          },
          {
            name: 'users.company_id',
            th: 'Company Name',
            render: ({ user }) => user?.company?.name || '----',
            sortable: false,
          },
          {
            align: 'right',
            name: 'loan_amount',
            th: 'Loan Amount',
            render: ({ loan_amount: loanAmount }) =>
              `₦ ${this.$options.filters.currency(loanAmount)}`,
          },
          {
            name: 'interest_rate',
            th: 'Interest Rate',
            render: ({ interest_rate }) => interest_rate,
          },
          {
            name: 'users.source',
            th: 'User Source',
            render: ({ user }) => user?.source,
          },
          {
            name: 'source',
            th: 'Loan Source',
            render: ({ source }) => source,
          },
          {
            name: 'loans.status',
            th: 'Status',
          },
          {
            name: 'loans.created_at',
            th: 'Request Date',
            render: ({ created_at: createdAt }) =>
              this.$options.filters.dateFormat(createdAt, 'D, dd M Y h:ia'),
          },
        ],
      }),
      approvingLoan: false,
      approved: false,
      decliningLoan: false,
      declined: false,
    };
  },
  computed: {
    isOpen() {
      return !!this.loan;
    },
    employee() {
      return this.loan?.user;
    },
    loanTenure() {
      return this.loan?.tenure || 0;
    },
    payDayLoanLimit() {
      const salary = this.loan?.user?.profile?.salary;
      var limit = 0;
      if (salary) {
        limit = Number(salary) * 0.5;
      }

      return limit;
    },
    standardLoanLimit() {
      const salary = this.loan?.user?.profile?.salary;
      var limit = 0;
      if (salary) {
        limit = Number(salary) * 0.5 * 12;
      }

      return limit;
    },
    updatingLoan() {
      return this.approvingLoan || this.decliningLoan;
    },
    updated() {
      return this.approved || this.declined;
    },
  },
  beforeMount() {
    this.reloadTable();
  },
  methods: {
    click(loan) {
      this.loans.selected = loan;
      if (loan) {
        this.$refs.loanModal.open();
      }
    },
    loanModalClosed() {
      this.loans.selected = null;
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }
      this.$refs.table.clickedFilter(this.currentFilter);
    },
    async approve() {
      this.approvingLoan = true;
      await this.sendRequest('admin.loans.personal.approve', this.loan.id, {
        success: (response) => {
          const {
            data: { loan },
          } = response.data;
          // this.loan = response.data.data;
          this.approved = true;
          this.$success({
            title: 'Operation Successful',
            body: 'Loan Request Approved.',
            button: {
              text: 'View Offer Letter',
              onClick: () =>
                window.open(
                  this.$router.resolve({
                    name: 'offer-letter',
                    params: {
                      loanId: loan.id,
                    },
                  }).href,
                  '_blank'
                ),
            },
          });
          this.reloadTable();
        },
        error: (error) => {
          this.error = error;
        },
      });
      this.approvingLoan = false;
    },
    async decline() {
      this.decliningLoan = true;
      await this.sendRequest('admin.loans.personal.decline', this.loan.id, {
        success: () => {
          // this.loan = response.data.data;
          this.declined = true;
          this.$success({
            title: 'Operation Successful',
            body: 'Loan Request Declined.',
            button: 'Okay',
          });
          this.reloadTable();
        },
        error: (error) => {
          this.error = error;
        },
      });
      this.decliningLoan = false;
    },
    async reloadTable() {
      this.$refs.table?.loadAjaxData();
    },
  },
};
</script>
